import React, { Component } from "react"
import Link from "gatsby-link"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import logoImg from "../images/placeholder3.png"
import NavBar from "./navbar"

import '../components/header.css'

import {
  faFacebookF,
  faTwitter,
  faGooglePlusG,
  faLinkedinIn,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons"

export class Header extends Component {
  render() {
    return (
      <section className="header-wrapper">
        <div className="header-container">
          <div className="row">
            <div className="col-lg-12">
              <Link to="/" className="brand-logo">
                <img src={logoImg} alt="brand logo" />
              </Link>
              <NavBar />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Header
