import React, { Component } from "react"
import logoImg from "../images/placeholder.png"
import { Link } from "gatsby"

export class FooterTwo extends Component {
  render() {
    return (
      <section className="footer-wrapper pad-50 bg-lightblue style-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 widget text-center">
              <p className="copyright">
                Where is Tanya | Copyrights © 2019
                <br />
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default FooterTwo
