import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import FooterTwo from "../components/footerTwo"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"
import "bootstrap/dist/css/bootstrap.css"
import "./base.css"

const Template = ({ children }) => (
    <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
        allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
          edges {
            node {
              title
              slug
              publishDate(formatString: "MMMM Do, YYYY")
              body {
                body
              }
            }
          }
        }
      }
    `}
    render={data => (
      <div class='body'>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            {
              name: "description",
              content: data.site.siteMetadata.description,
            },
            { name: "keywords", content: "sample, something" },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <Header />
        <div class='layout-container'>
          {children}
        </div>
        <FooterTwo />
      </div>
    )}
  />
)

export default Template
