import React, { Component } from "react"
import Link from "gatsby-link"
import '../components/header.css'

export class NavBar extends Component {
  render() {
    return (
      <nav id="main-menu" className="text-center">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/blogposts">Blog</Link>
          </li>
          <li>
            <Link to="/trips/">Trip Gallery</Link>
          </li>
        </ul>
      </nav>
    )
  }
}

export default NavBar
